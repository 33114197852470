import Vue from 'vue';
import { FormFieldProps } from './FormField.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'FormField',
	props: FormFieldProps,
	computed: {
		isMessageSlotVisible(): boolean {
			return (
				(this.$slots?.message || [])?.filter((vnode) => vnode.children || vnode.text?.trim())
					.length > 0
			);
		},
	},
});
