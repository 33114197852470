import { PropType } from 'vue';
import { Props } from '../../types/core';
import { TextFieldType } from '../../types/textField';

// Model -------------------------------------------------------------------------------------------

export interface TextFieldModel {
	uid: string;
	id: string;
	value: string;
	hasError?: boolean;
	label?: string;
	type: TextFieldType;
	disabled?: boolean;
	placeholder?: string;
	textarea?: boolean;
	isFloated?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const TextFieldProps: Props<TextFieldModel> = {
	uid: {
		type: String as PropType<TextFieldModel['uid']>,
		required: true,
	},
	id: {
		type: String as PropType<TextFieldModel['id']>,
		required: true,
	},
	value: {
		type: String as PropType<TextFieldModel['value']>,
		default: '',
	},
	hasError: {
		type: Boolean as PropType<TextFieldModel['hasError']>,
		default: false,
	},
	label: {
		type: String as PropType<TextFieldModel['label']>,
		default: '',
	},
	type: {
		type: String as PropType<TextFieldModel['type']>,
		default: TextFieldType.TEXT,
	},
	disabled: {
		type: Boolean as PropType<TextFieldModel['disabled']>,
		default: false,
	},
	placeholder: {
		type: String as PropType<TextFieldModel['placeholder']>,
		default: null,
	},
	textarea: {
		type: Boolean as PropType<TextFieldModel['textarea']>,
		default: false,
	},
	isFloated: {
		type: Boolean as PropType<TextFieldModel['isFloated']>,
		default: false,
	},
};
