import { PropType } from 'vue';
import { Props } from '../../types/core';

// Model -------------------------------------------------------------------------------------------

export interface FormFieldModel {
	hasError?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const FormFieldProps: Props<FormFieldModel> = {
	hasError: {
		type: Boolean as PropType<FormFieldModel['hasError']>,
		default: false,
	},
};
