import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Link } from '../../types/link';
import { TriggerIconPosition, TriggerTag, TriggerTheme } from '../../types/trigger';

// Model -------------------------------------------------------------------------------------------

export interface TriggerModel extends Omit<Link, 'text'> {
	uid: string;
	icon?: string;
	iconPosition?: TriggerIconPosition;
	tag: TriggerTag;
	theme: TriggerTheme;
	disabled?: boolean;
	fakeDisabled?: boolean;
	loading?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const TriggerProps: Props<TriggerModel> = {
	uid: {
		type: String as PropType<TriggerModel['uid']>,
		required: true,
	},
	href: {
		type: String as PropType<TriggerModel['href']>,
	},
	tag: {
		type: String as PropType<TriggerModel['tag']>,
		default: TriggerTag.BUTTON,
	},
	icon: {
		type: String as PropType<TriggerModel['icon']>,
	},
	iconPosition: {
		type: String as PropType<TriggerModel['iconPosition']>,
	},
	theme: {
		type: String as PropType<TriggerModel['theme']>,
		default: TriggerTheme.OUTLINED,
	},
	disabled: {
		type: Boolean as PropType<TriggerModel['disabled']>,
		default: false,
	},
	fakeDisabled: {
		type: Boolean as PropType<TriggerModel['fakeDisabled']>,
		default: false,
	},
	loading: {
		type: Boolean as PropType<TriggerModel['loading']>,
		default: false,
	},
	target: {
		type: String as PropType<TriggerModel['target']>,
	},
};
