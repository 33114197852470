export enum TriggerTheme {
	OUTLINED = 'outlined',
	CTA = 'cta',
	TEXT = 'text',
	ROUNDED = 'rounded',
	BRIGHT = 'bright',
	NARROW = 'narrow',
}

export enum TriggerTag {
	BUTTON = 'button',
	LINK = 'Link',
	A = 'a',
}

export enum TriggerIconPosition {
	RIGHT = 'right',
	LEFT = 'left',
}
