import Vue from 'vue';
import { TextFieldType } from '../../types/textField';
import { TextFieldProps } from './TextField.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'TextField',
	inheritAttrs: false,
	model: {
		prop: 'value',
		event: 'model-input',
	},
	props: TextFieldProps,
	data() {
		return {
			localValue: '',
			isFocused: false,
			isPasswordVisible: false,
		};
	},
	computed: {
		isLabelFloated(): boolean {
			return !!this.localValue || this.isFocused || !!this.isFloated || !!this.placeholder;
		},
		attributes(): Record<string, unknown> {
			const isNumberType = this.type === TextFieldType.NUMBER;
			const isTextType = isNumberType || this.isPasswordVisible;

			return {
				...this.$attrs,
				id: this.id,
				...(this.disabled ? { disabled: this.disabled } : {}),
				...(this.placeholder ? { placeholder: this.placeholder } : {}),
				type: isTextType ? TextFieldType.TEXT : this.type,
				inputmode: isNumberType ? 'numeric' : null,
				class: {
					'tw-block tw-px-4 tw-text-base tw-font-bold tw-border tw-rounded tw-w-full tw-transition-none focus:tw-outline-none':
						true,
					'tw-border-snow': !this.isFocused && !this.hasError,
					'tw-border-storm': this.isFocused && !this.hasError,
					'tw-border-shiraz': this.hasError,
					'tw-bg-white tw-text-black hover:tw-bg-dove': !this.disabled,
					'tw-bg-snow tw-text-storm tw-cursor-not-allowed': this.disabled,
					'tw-py-4': !this.label || (this.label && !this.isLabelFloated),
					'tw-pt-6 tw-pb-2': this.label && this.isLabelFloated,
					'tw-resize-y': this.textarea,
					'tw-pr-20': this.type === TextFieldType.PASSWORD,
				},
			};
		},
		isRightSlotVisible(): boolean {
			return (
				(this.$slots?.right || [])?.filter((vnode) => vnode.children || vnode.text?.trim()).length >
				0
			);
		},
	},
	watch: {
		value() {
			this.initialize();
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		initialize() {
			this.localValue = this.value;
		},
		handleInput(event: InputEvent) {
			const input = event.target as HTMLInputElement;

			if (this.type === TextFieldType.NUMBER && input.value.match(/\D/)) {
				input.value = input.value.replace(/\D/g, '');
			}

			this.$emit('input', event);
			this.$emit('model-input', input.value);

			this.localValue = input.value;
		},
		handleFocus() {
			this.isFocused = true;
		},
		handleBlur() {
			this.isFocused = false;
		},
		focus() {
			(this.$refs?.input as HTMLInputElement)?.focus?.();
		},
	},
});
