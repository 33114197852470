import Vue from 'vue';
import { TriggerIconPosition, TriggerTag, TriggerTheme } from '../../types/trigger';
import Ico from '../Ico/Ico.vue';
import { TriggerProps } from './Trigger.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Trigger',
	components: { Ico },
	props: TriggerProps,
	data() {
		return {
			isTheme: {
				outlined: this.theme === TriggerTheme.OUTLINED,
				cta: this.theme === TriggerTheme.CTA,
				text: this.theme === TriggerTheme.TEXT,
				rounded: this.theme === TriggerTheme.ROUNDED,
				bright: this.theme === TriggerTheme.BRIGHT,
				narrow: this.theme === TriggerTheme.NARROW,
			},
			triggerIconPosition: {
				left: this.iconPosition === TriggerIconPosition.LEFT,
				right: this.iconPosition === TriggerIconPosition.RIGHT,
			},
		};
	},
	computed: {
		props(): Object {
			const props = {};

			if (this.tag === TriggerTag.LINK) {
				Object.assign(props, { link: { href: this.href, target: this.target } });
			} else if (this.tag === TriggerTag.A) {
				Object.assign(props, { href: this.href, target: this.target, uid: this.uid });
			}

			return props;
		},
		triggerIcon(): string | undefined {
			return this.loading ? 'spin' : this.icon;
		},
		hasText(): boolean {
			return !!(this.$slots.default?.[0]?.text || '').trim();
		},
	},
	watch: {
		theme(): void {
			this.updateTheme();
		},
	},
	methods: {
		updateTheme(): void {
			this.isTheme = {
				outlined: this.theme === TriggerTheme.OUTLINED,
				cta: this.theme === TriggerTheme.CTA,
				text: this.theme === TriggerTheme.TEXT,
				rounded: this.theme === TriggerTheme.ROUNDED,
				bright: this.theme === TriggerTheme.BRIGHT,
				narrow: this.theme === TriggerTheme.NARROW,
			};
		},
	},
});
